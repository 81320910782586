<template>
  <div>
    <div class="is-flex is-justify-content-space-between">
      <label class="label">Phones</label>
      <button v-show="phones.length < 4"
        class="button is-small is-primary is-light"
        @click="addTmpPhone"
        :disabled="!$userInfo.isSystemAccount">
        <i class="icon mdi mdi-plus mdi-24px" />
      </button>
    </div>
    <div v-for="(assetPhone, index) in phones"
      :key="index"
      class="field has-addons">
      <div class="control">
        <span class="select">
          <select :disabled="!$userInfo.isSystemAccount"
            v-model="assetPhone.type"
            :class="{ 'is-invalid' : !$v.phones.types.uniquePhoneType }"
            v-tippy="{ content: 'Duplicate Type', theme: 'danger', enabled: !$v.phones.types.uniquePhoneType }">
            <option v-for="(type, typeIndex) in phoneTypes"
              :key="typeIndex"
              :value="type.toString()"
              @change="$v.phones.types.touch()">
              {{ typeIndex }}
            </option>
          </select>
        </span>
      </div>

      <div class="control is-expanded">
        <input class="input"
          type="text"
          v-model="assetPhone.no"
          @input="$v.phones.$each[index].no.$touch()"
          :disabled="!$userInfo.isSystemAccount">
        <span class="help is-danger"
          v-if="!$v.phones.$each[index].no.required">Phone number is required
        </span>
      </div>

      <p class="control">
        <button class="button has-text-gold is-outlined"
          :title="[assetPhone.isDefault ? 'Default Phone' : '']"
          :disabled="!$userInfo.isSystemAccount || (phones.length == 1 && assetPhone.isDefault)"
          @click="setDefault(assetPhone)">
          <span class="icon">
            <i class="mdi mdi-24px"
              :class="[assetPhone.isDefault ? 'mdi-star': 'mdi-star-outline']" />
          </span>
        </button>
      </p>
      <p class="control">
        <button class="button has-text-danger"
          @click="removePhone(assetPhone)"
          :disabled="!$userInfo.isSystemAccount || assetPhone.isDefault">
          <span class="icon">
            <i class="mdi mdi-minus mdi-24px" />
          </span>
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import phonesValidation from '@/assets/validateForms/Phones'
import { PhoneTypes } from '@/enums'

export default {
  name: 'CompanyAssetPhones',

  props: {
    phones: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    phoneTypes() {
      return PhoneTypes
    }
  },

  mounted() {
    this.$v.$touch()
  },

  methods: {
    addTmpPhone() {
      this.$emit('addTmpPhone')
    },
    removePhone(assetPhone) {
      this.$emit('removePhone', assetPhone)
    },
    setDefault(assetPhone) {
      this.$emit('setDefault', assetPhone, 'assetPhones')
    }
  },

  validations: {
    ...phonesValidation
  }
}
</script>

<style lang="scss" scoped>
.is-invalid {
  background-color: pink !important;
}
</style>
