import { required, email } from 'vuelidate/lib/validators'

const uniqueEmailType = (value, emails) => {
  const types = emails.map(e => e.type)
  return new Set(types).size == types.length
}

export default {
  emails: {
    $each: {
      address: {
        required,
        email
      }
    },
    types: {
      uniqueEmailType
    }
  }
}
